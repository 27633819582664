
.envelope{
  width: 100%;
  height: 100%;
  position: fixed;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  z-index: 1000; /* Ensure it covers the entire screen */
}

:root {
  --top-content-color: black;
  --internal-c-fill: black;
  --bottom-span-color: black;
  --cover-a-color: black;
  --top-bottom-bs-color:black;
  --top-bottom-color:black;
}

.theme1 {
  --top-content-color: #8A5A57;
  --internal-c-fill: #8A5A57;
  --bottom-span-color: #8A5A57;
  --cover-a-color: #8A5A57;
  --top-bottom-bs-color:rgba(165, 125, 121, 0.4);
  --top-bottom-color:linear-gradient(135deg, #E1C7C3, #C79E9A);
}

.theme2 {
  --top-content-color: #514f53;
  --internal-c-fill: #514f53;
  --bottom-span-color: #514f53;
  --cover-a-color: #514f53;
  --top-bottom-bs-color:rgba(165, 125, 121, 0.4);
  --top-bottom-color:linear-gradient(135deg, #c5b8ce , #a19ba9 );
}

.theme3 {
  --top-content-color: #023f90;
  
  --internal-c-fill: #023f90;
  --bottom-span-color: #023f90;
  --cover-a-color: #023f90;
  --top-bottom-bs-color:rgba(120, 122, 195, 0.4);
  --top-bottom-color:linear-gradient(135deg, #D6D7FB, #B3D1E6);;
  
}

.theme4 {
  --top-content-color: #5A4E3D;
  --internal-c-fill: #5A4E3D;
  --bottom-span-color: #5A4E3D;
  --cover-a-color: #5A4E3D;
  --top-bottom-bs-color:rgba(77, 22, 17, 0.4) ;
  --top-bottom-color:linear-gradient(135deg,  #ded2be   ,  #c6b299);
}

.theme5 {
  --top-content-color: #F0E6DC;
  --internal-c-fill: #F0E6DC;
  --bottom-span-color: #F0E6DC;
  --cover-a-color: #F0E6DC;
  --top-bottom-bs-color:rgba(139, 58, 50, 0.4);
  --top-bottom-color: linear-gradient(135deg, #d3a596 , #a75d56); 
}

.theme6 {
  --top-content-color: #4A2F29;
  --internal-c-fill: #4A2F29;
  --bottom-span-color: #4A2F29;
  --cover-a-color: #4A2F29;
  --top-bottom-bs-color:rgba(252, 124, 104, 0.4);
  --top-bottom-color:linear-gradient(135deg,  #e5b4a3  , #c78770);
}

.theme7 {
  --top-content-color: #8A5A57;
  --internal-c-fill: #8A5A57;
  --bottom-span-color: #8A5A57;
  --cover-a-color: #8A5A57;
  --top-bottom-bs-color:rgba(165, 125, 121, 0.4);
  --top-bottom-color:linear-gradient(135deg, #E1C7C3, #C79E9A);
}

/* 
.theme1.envelope{
  background: #F5E8E6;
} */

.theme2.envelope{
   background: #e9e7f2;
}

.theme6.envelope{
 background-size: cover;
}

/* .theme3.envelope{
  background:  #F0F1FF;
} */

.hidden {
  opacity: 0; /* Make it fully transparent */
  pointer-events: none; /* Ensure it's not interactable */
}

.top, .bottom{
  transition: transform 1s ease-in-out;
  position: absolute;
  width: 100%;
}


.top{
  top: 0;
  height: 50vh;
  border-radius:0 0 50% 50% / 0% 0% 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(0);
  box-shadow: 0px 8px 15px var(--top-bottom-bs-color);
  background: var(--top-bottom-color);
}

.bottom{
  bottom: 0;
  height: 45vh;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: end;
  box-shadow: 0px -5px 10px var(--top-bottom-bs-color);
  background: var(--top-bottom-color);
}
.bottom > span{
  padding: 10px 0;
  text-transform: capitalize;
  color: var(--bottom-span-color);
  font-size: 16px;
}
.cover-a{
  color: var(--cover-a-color);
}
.wax-seal {
  position: absolute;
  top: 53%;
  left: 50%;
  width: 150px; /* Adjust size as needed */
  height: 150px; /* Adjust size as needed */
  background: url('https://res.cloudinary.com/depixc8dq/image/upload/v1739982666/aamantran%20assets/waxseal_kgk3xl.png') no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 800ms ease;
}

.wax-seal:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.top-image{
  margin-top: 30px; /* decrese if necessry */
  width: 140px;
  height: 150px;
}

.top-content{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 80%;
  color: var(--top-content-color);
}
.special-class{
  transform: translateY(0) !important;
}
.top-content > pre{
  overflow: hidden;
}


.idol-img{
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

#internal-c {
  fill: var(--internal-c-fill) !important;
}

.envelope.animate .top {
  transform: translateY(-100%);
}

.envelope.animate .bottom {
  transform: translateY(100%);
}

.envelope.animate {
  opacity: 0;
  transform: scale(1.2);
  transition: opacity 800ms ease-in-out, transform 800ms ease-in-out;
}

@media(min-width:374px){
  .wax-seal {
    top: 53%;
    left: 49%;
  }
  .wax-seal{
    width: 180px;
    height: 180px;
  }
  .top-image{
    margin-top: 20px;
    width: 140px;
    height: 150px;
  }
  .top-content{
    margin-top: 10px;
    font-size: 20px;
  }
  .top{
    height: 50vh;
    border-radius:0 0 50% 50% / 0% 0% 90% 90%;
  }
  
  .bottom{
    height: 45vh;
    border-radius: 50% 50% 0 0 / 90% 90% 0 0;
  }
  .bottom > span{
    font-size: 16px;
  }
}

@media(min-width:424px){
  .wax-seal{
    width: 180px;
    height: 180px;
  }
  .top-image{
    width: 150px;
    height: 170px;
  }
  .top-content{
    font-size: 20px;
  }
  .top{
    height: 50vh;
    border-radius:0 0 50% 50% / 0% 0% 90% 90%;
  }
  
  .bottom{
    height: 45vh;
    border-radius: 50% 50% 0 0 / 90% 90% 0 0;
  }
  .bottom > span{
    font-size: 20px;
  }
}

@media(min-width:767px){
 .envelope{
  display: flex;
  justify-content: center;
 }  
  
  .top{
    width: 90%;
  }
  
  .bottom{
    width: 90%;
  }
  .wax-seal{
    width: 170px;
    height: 170px;
  }
  .top-image{
    width: 140px;
    height: 200px;
    transform: translateY(-40px);
  }
  .top-content{
    transform: translateY(-70px);
    font-size: 20px;
  }
  .bottom > span{
    font-size: 20px;
  }
}

@media(min-width:1023px){
.top{
  width: 80%;
  height: 50vh;
}
.bottom{
  width: 80%;
  height: 45vh;
}

.wax-seal{
  width: 180px;
  height: 180px;
  top: 54%;
  left: 50%;
}
.top-image{
  width: 160px;
  height: 190px;
  transform: translateY(0px);
}
.top-content{
  transform: translateY(-20px);
  font-size: 22px;
}
.bottom > span{
  font-size: 22px;
}
}